import { add } from 'src/modules/vsf-storyblok-module/components'

// Plugins
add('WYSIWYGBlok', () => import('./Plugins/WYSIWYGBlok.vue'))
add('Table', () => import('./Plugins/Table.vue'))

// Blocks
add('BrowseByBrand', () => import('./Blocks/BrowseByBrand.vue'))
add('Advert', () => import('./Blocks/Advert.vue'))
add('GeneralSlider', () => import('./Blocks/GeneralSlider.vue'))
add('SpecialOfferList', () => import('./Blocks/SpecialOfferList.vue'))
add('SpecialOfferItem', () => import('./Blocks/SpecialOfferItem.vue'))
add('SpecialOfferItemRight', () => import('./Blocks/SpecialOfferItemRight.vue'))
add('SpecialOfferItemLeft', () => import('./Blocks/SpecialOfferItemLeft.vue'))
add('SpecialOfferProduct', () => import('./Blocks/SpecialOfferProduct.vue'))
add('PageTitle', () => import('./Blocks/PageTitle.vue'))
add('PageContent', () => import('./Blocks/PageContent.vue'))
add('BrandStocking', () => import('./Blocks/BrandStocking.vue'))
add('Wysiwyg', () => import('./Blocks/Wysiwyg.vue'))
add('WysiwygContainer', () => import('./Blocks/WysiwygContainer.vue'))
add('Button', () => import('./Blocks/Button.vue'))
add('RewardsAsideImage', () => import('./Blocks/RewardsAsideImage.vue'))
add('RewardsLinks', () => import('./Blocks/RewardsLinks.vue'))
add('Row', () => import('./Blocks/Row.vue'))
add('PageNotFound', () => import('./Blocks/PageNotFound.vue'))
add('Raw HTML', () => import('./Blocks/RawHTML.vue'))
add('QuickOrderInstructions', () => import('./Blocks/QuickOrderInstructions.vue'))

add('Issuu', () => import('./Blocks/Issuu.vue'))

// Pages
add('page', () => import('./Pages/Page.vue'), { force: true })

// Header
add('HeaderMenu', () => import('./Header/HeaderMenu.vue'))
add('HeaderMenuLink', () => import('./Header/HeaderMenuLink.vue'))
add('HeaderMenuDropdown', () => import('./Header/HeaderMenuDropdown.vue'))
add('HeaderMenuDropdownLink', () => import('./Header/HeaderMenuDropdownLink.vue'))

add('PageHero', () => import('./Blocks/PageHero.vue'))

add('FavoriteItem', () => import('./Blocks/FavoriteItem.vue'))
add('Column', () => import('./Blocks/Column.vue'))

add('ImageWithLink', () => import('./Blocks/ImageWithLink.vue'))
