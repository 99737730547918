/* Recommended module for LBPPRO theme
Badge config options:
customRecommendedEnabled: enable badges
customRecommendedThreshold: days since last order to consider for badge text
customRecommendedCategoryId: category to pull custom recommended products from, not used if customRecommendedSource is local
customRecommendedLocal: array of skus to use for local badge testing, not used if customRecommendedSource is remote
customRecommendedSource: "local" or "remote"
enableRefillPage: boolean, if true, enables the "Refill your Favorites" page, which uses customer's most frequently ordered products out of the order history
badgeShape: "circle", "pill", "starburst"
mode: "dev" or "prod"

*/

import { Module } from 'vuex'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RecommendedState from '../types/RecommendedState'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import config from 'config'

export const module: Module<RecommendedState, any> = {
  namespaced: true,
  state: {
    history: [],
    historyByFrequency: [],
    historyByRecency: [],
    predicted: [],
    customRecommended: {}
  },
  actions: {
    async initialize ({ dispatch }) {
      if (!config.recommended) return

      if (config.recommended.enableRefillPage) {
        dispatch('calculateHistoricalSuggestions')
      }

      if (config.recommended.customRecommendedSource === 'remote') {
        dispatch('fetchCustom')
      } else if (config.recommended.customRecommendedSource === 'local' && config.recommended.customRecommendedLocal.length) {
        // can use this for testing badge shapes and layouts as well, just add any skus to the config
        dispatch('setLocalCustomRecommended')
      }

    },
    setLocalCustomRecommended ({ dispatch, commit, rootState }) {
      if (!rootState || !rootState.user || !rootState.user.orders_history || !rootState.user.orders_history.items.length) {
        setTimeout(() => {
          dispatch('setLocalCustomRecommended')
        }, 1000)
        return
      }
      let customRecommended = {}

      for (let skuIdx in config.recommended.customRecommendedLocal) {
        // get days since last order from user's order history, -1 if not found
        let daysSinceLastOrder = -1
        let sku = config.recommended.customRecommendedLocal[skuIdx]

        if (rootState.user.orders_history && rootState.user.orders_history.items.length) {
          for (let order in rootState.user.orders_history.items) {
            let orderSkus = rootState.user.orders_history.items[order].items.map(item => item.sku)

            if (orderSkus.includes(sku)) {
              let lastOrder = (rootState.user.orders_history.items[order] || {}).created_at

              let lastOrderDate = new Date(lastOrder)
              let today = new Date()

              daysSinceLastOrder = Math.floor((today.getTime() - lastOrderDate.getTime()) / (1000 * 60 * 60 * 24))
              break
            }
          }
        }
        customRecommended[sku] = daysSinceLastOrder
      }
      commit('setCustomRecommended', customRecommended)
    },
    async productFetch ({dispatch, commit}, skus) {
      let query = new SearchQuery()

      query.applyFilter({
        key: 'sku',
        value: {'in': skus}
      })

      return dispatch('product/list', {
        query,
        sort: 'position:desc',
        size: 1000,
        excludeFields: [
          'ingredients_long', 'ingredients_short', 'youtube_video_url', 'subtitle', 'new_image_sizing', 'howto_video',
          'short_description', 'background_video', 'description', 'media_gallery', 'role_page_background',
          'how_it_works', 'small_image', 'media_gallery'
        ]
      }, {root: true})
    },
    async fetchPredicted ({ commit, rootState }, { key, size = 4 }) {
      //const { items } = await fetchPredicted({ key, size })
      //commit('setPredicted', items)
      return
    },
    async fetchCustom ({ dispatch, commit, rootState }) {
      // get last 30 days of orders
      const daysAgo = config.recommended.customRecommendedThreshold || 30
        TaskQueue.execute({ url: config.users.history_endpoint.concat(`&daysAgo=${daysAgo}`),
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        }).then((resp: any) => {
          if (resp.code === 200) {
            console.log(resp.result)
          }
        })

      const category_id = config.recommended.customRecommendedCategoryId|| 0

      if (!category_id) {
        commit('setCustomRecommended', {})
        return
      }

      let res = await dispatch('product/list', {
        query: (new SearchQuery()).applyFilter({
          key: 'category_ids',
          value: {'in': [category_id]}
        }),
        sort: 'position:desc',
        size: 1000,
        includeFields: ['sku']
      }, {root: true})

      if (res) {
        // separate out sku and days since last order
        let items = {}
        res.items.forEach(item => {
          // get days since last order from user's order history, -1 if not found
          let daysSinceLastOrder = -1
          if (rootState.user.orders_history && rootState.user.orders_history.items.length) {
            let lastOrder = rootState.user.orders_history.items[0].created_at
            let lastOrderDate = new Date(lastOrder)
            let today = new Date()
            daysSinceLastOrder = Math.floor((today.getTime() - lastOrderDate.getTime()) / (1000 * 60 * 60 * 24))
          }
          items[item.sku] = daysSinceLastOrder
        })
        commit('setCustomRecommended', items)
      }
    },
    async calculateHistoricalSuggestions ({ dispatch, commit, rootState }) {
      // build two arrays of skus, one sorted by frequency and one sorted by recency, with count and lastOrder on both
      // array of {sku: string, count: number, lastOrder: number} lastOrder is a unix timestamp
      if (!rootState || !rootState.user || !rootState.user.orders_history || !rootState.user.orders_history.items.length) {
        setTimeout(() => {
          dispatch('calculateHistoricalSuggestions')
        }, 1000)
        return
      }
      // rootState.user.orders_history.items[orderIdx].created_at => "YYYY-MM-DD HH:MM:SS"
      // rootState.user.orders_history.items[orderIdx].items.map(item => { item.sku })<string>
      // rootState.recommended.history = [ sku ] -> sorted by frequency
      // build list of skus from all orders, sorted by frequency and filtered by time since last order (30 days? 60?)
      // build list of { sku: string, count: number, lastOrder: number(timestamp) } from all orders
      let items = []
      let recency = []
      let frequency = []
      rootState.user.orders_history.items.forEach(order => {
        order.items.forEach(item => {
          // create a new item if it doesn't exist
          items[item.sku] = items[item.sku] ? items[item.sku] : { count: 0, lastOrder: 0 }

          // convert last order date to timestamp
          const lastOrder = new Date(order.created_at).getTime()
          // update time to most recent order
          items[item.sku].lastOrder = lastOrder > items[item.sku].lastOrder ? lastOrder : items[item.sku].lastOrder

          // increment count
          items[item.sku].count++
        })
      })
      let res = await dispatch('productFetch', Object.keys(items))
      if (res) {
        res.items.forEach(item => {
          frequency.push({ sku: item.sku, count: items[item.sku].count, lastOrder: items[item.sku].lastOrder, product: item })
          recency.push({ sku: item.sku, count: items[item.sku].count, lastOrder: items[item.sku].lastOrder, product: item })
        })
      }
      frequency.sort((a, b) => b.count - a.count)
      recency.sort((a, b) => b.lastOrder - a.lastOrder)
      // just calculate frequency and recency up here and commit, then component can choose which to use/do further filtering without worrying about race conditions for
      // computed values
      commit('setHistory', {items, frequency, recency})
    }
  },
  mutations: {
    setCustomRecommended (state, items) {
      state.customRecommended = items
    },
    setPredicted (state, items) {
      state.predicted = items
    },
    setHistory (state, {items, frequency, recency}) {
      // precomputing and storing the sorted histories, doesn't make sense to re-run a sort on every getter call
      // not doing any thresholding, that's relatively trivial to do when constructing the template
      state.history = items
      state.historyByFrequency = frequency
      state.historyByRecency = recency
    }
  },
  getters: {
    history (state) {
      return state.history
    },
    historyByFrequency (state) {
      return state.historyByFrequency
    },
    historyByRecency (state) {
      return state.historyByRecency
    },
    predicted (state) {
      return state.predicted
    },
    customRecommended (state) {
      return state.customRecommended
    }
  }
}
